import type { FunctionComponent } from "react"

import { clsx } from "clsx"

import { Text, TEXT_ALIGNS, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import styles from "./Title.module.css"

type TitleProps = {
  title: string
  isCentered?: boolean
  isSmall?: boolean
  tag: string
  className?: string
}

export const Title: FunctionComponent<TitleProps> = ({
  title,
  className,
  tag,
  isCentered = false,
  isSmall = false,
}) => (
  <Text
    className={clsx(styles.title, { [styles["title--small"]]: isSmall }, className)}
    type={isSmall ? TEXT_TYPES.SUBHEAD_SMALL : TEXT_TYPES.HEADING_SMALL}
    align={isCentered ? TEXT_ALIGNS.CENTER : TEXT_ALIGNS.LEFT}
    weight={TEXT_WEIGHTS.BLACK}
    resetSpacing
    tag={tag}
  >
    {title}
  </Text>
)
